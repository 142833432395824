import { Typography } from '@components/text/text'

export function RegistrationLoftyDesc() {
	return (
		<div className="col-xs-12">
			<Typography type="h6">
				Lofty’s AI-powered platform is custom-built to help real estate agents and teams increase productivity
				and accelerate business growth. Featuring award-winning technology, Lofty is designed to optimize every
				step of the real estate journey — from search to settlement.
			</Typography>
		</div>
	)
}
