import { store } from '@redux/store'
import { AxiosResponse } from 'axios'

import {
	apiVersionURL,
	httpRequest,
	httpRequestNoErrors,
	httpRequestNoToken,
	RequestBaseURL,
} from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { AxiosRequestParams, GetItemsParams, GetItemsResponse } from '../utility.types'
import { LokationResource, LokationResourceSearchParams } from './resources.types'

export class ResourcesAPI {
	public static getResources(
		props: GetItemsParams<LokationResource>,
	): Promise<AxiosResponse<{ items: LokationResource[] }>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })

		const requestPromise = httpRequestNoToken.get(`${RequestBaseURL}${apiVersionURL}/resources/`, {
			headers,
			params,
		})
		return requestPromise
	}

	public static findResources(
		props: GetItemsParams<LokationResource>,
		itemTypeProps: LokationResourceSearchParams,
	): Promise<AxiosResponse<GetItemsResponse<LokationResource>>> {
		const headers = {}

		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })
		const requestPromise = httpRequestNoToken.get(`${RequestBaseURL}${apiVersionURL}/resources/search`, {
			headers,
			params,
		})
		return requestPromise
	}

	public static findResourcesAdmin(
		props: GetItemsParams<LokationResource>,
		itemTypeProps: LokationResourceSearchParams,
	): Promise<AxiosResponse<GetItemsResponse<LokationResource>>> {
		const headers = {}

		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props, ...itemTypeProps })
		const requestPromise = httpRequestNoToken.get(`${RequestBaseURL}${apiVersionURL}/resources/admin_search`, {
			headers,
			params,
		})
		return requestPromise
	}

	public static getResourceById(id: number): Promise<AxiosResponse<LokationResource>> {
		const headers = {}
		const params: AxiosRequestParams = { sort: [] }

		const requestPromise = httpRequestNoToken.get(`${RequestBaseURL}${apiVersionURL}/resources/${id}`, {
			headers,
			params,
		})
		return requestPromise
	}

	public static createResource(
		resource: Omit<LokationResource, 'resourceId'>,
	): Promise<AxiosResponse<LokationResource>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. FAQ cannot be created`)
		}

		const headers = {}
		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/resources/`, resource, {
			headers,
		})
		return requestPromise
	}

	public static updateResource(
		resourceId: number,
		updateResourceDTO: Partial<LokationResource>,
	): Promise<AxiosResponse<LokationResource>> {
		const userProps = store.getState().user
		if (!userProps) {
			throw new Error(`User not found. Tag cannot be updated`)
		}

		const headers = {}
		const requestPromise = httpRequest.patch(
			`${RequestBaseURL}${apiVersionURL}/resources/${resourceId}`,
			updateResourceDTO,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static deleteResource(resourceId: number): Promise<AxiosResponse<number>> {
		const headers = {}
		const requestPromise = httpRequest.delete(`${RequestBaseURL}${apiVersionURL}/resources/${resourceId}`, {
			headers,
		})
		return requestPromise
	}

	public static updateResourcePicture(resourceId: number, photo: string): Promise<AxiosResponse<string>> {
		const headers: { [key: string]: any } = {
			'Content-Type': 'text/plain',
		}

		const requestPromise = httpRequestNoErrors.post(
			`${RequestBaseURL}/api/v1/resources/${resourceId}/resourcePicture`,
			photo,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static deleteResourcePicture(resourceId: number): Promise<AxiosResponse<string>> {
		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/resources/${resourceId}/resourcePicture`,
		)

		return requestPromise
	}
}
