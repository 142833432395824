import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'

export function FloridaDepartmentContacts() {
	return (
		<Paper bgColor="primary" padding={['all']}>
			<div className="flex flex-wrap flex-justifyContent-center mb-20">
				<div className="col-12 col-md-4 pr-20 pr-0-md-down mb-20-md-down">
					<div>
						<div className="col-12 mb-20 text-center">
							<Typography type="h6">Department Email Contacts</Typography>
						</div>
						<ul>
							<li>
								Compliance / Support -{' '}
								<a href="mailto:support@lokationre.com">support@lokationre.com</a>
							</li>
							<li>
								Broker Specific Needs - <a href="mailto:broker@lokationre.com">broker@lokationre.com</a>
							</li>
							<li>
								Document Submission -{' '}
								<a href="mailto:transactions@lokationre.com">transactions@lokationre.com</a>
							</li>
							<li>
								Commission / Payments -{' '}
								<a href="mailto:payments@lokationre.com">payments@lokationre.com</a>
							</li>
							<li>
								Billing Inquiries - <a href="mailto:billing@lokationre.com">billing@lokationre.com</a>
							</li>
							<li>
								LoKation Mortgage - <a href="mailto:info@lokationmtg.com">info@lokationmtg.com</a>
							</li>
							<li>
								The Closing Team - <a href="mailto:info@closingteamfl.com">info@closingteamfl.com</a>
							</li>
							<li>
								Programs - <a href="maillto:programs@lokationre.com">programs@lokationre.com</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="col-12 col-md-4 pr-20 pr-0-md-down mb-20-md-down">
					<div>
						<div className="col-12 mb-20 text-center">
							<Typography type="h6">Support</Typography>
						</div>
						<ul>
							<li>
								Phone: <a href="tel:954-545-5583">954-545-5583</a>
							</li>
							<li>Online Chat - Available in the bottom right corner of your window</li>
							<li>
								Set an Appointment with an Online Calendar for Agents:{' '}
								<a href="https://appt.link/agent-support-session" target="_blank" rel="noreferrer">
									Click to make appointment
								</a>
							</li>
							<li>
								Live Zoom Room for Support questions (available M-F 9am-5pm){' '}
								<a href="https://lokationsupport.com" target="_blank" rel="noreferrer">
									Click for Zoom
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="col-12 col-md-4">
					<div className="col-12 mb-20 text-center">
						<div className="col-12 mb-20 text-center">
							<Typography type="h6">Corporate Headquarters</Typography>
						</div>
						<div>1500 East Atlantic Blvd, Suite B</div>
						<div>Pompano Beach, Florida 33060</div>
						<div>
							<a href="tel:844-565-2846">844-LOKATION</a>
						</div>
						<div className="flex flex-justifyContent-center flex-column-md-down">
							<a href="https://WWW.LOKATIONRE.COM" target="_blank" rel="noreferrer">
								WWW.LOKATIONRE.COM
							</a>{' '}
							<span className="show-lg mx-5">|</span>{' '}
							<a href="mailto:INFO@LOKATIONRE.COM">INFO@LOKATIONRE.COM</a>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-justifyContent-center"></div>
		</Paper>
	)
}
