import './homepage-modal.scss'

import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { HomepageModalSettings } from 'src/services/homepage-modal-settings/homepage-modal-settings.types'
import { EndUserProps } from 'src/services/user/user.types'

interface HomepageModalProps {
	dismissModal: () => void
	currentUser: EndUserProps | null
	modalProps: HomepageModalSettings.CompleteResponse
}

function safelyDecodeURIComponent(content: string) {
	try {
		return decodeURIComponent(content)
	} catch (error) {
		console.error('Failed to decode modal content', error)
		return content // Return the raw content as a fallback
	}
}

function HomepageModalPrototype({ dismissModal, currentUser, modalProps }: HomepageModalProps) {
	return (
		<>
			<Modal
				onClose={dismissModal}
				maxWidth={800}
				maxHeight={825}
				fixedHeight={false}
				className="primary overflow-y__scroll"
			>
				<ModalHeader title={modalProps.title} center={true} />
				<ModalBody>
					<div>
						<div className="col-12 flex flex-wrap mb-20 flex-alignItems-center flex-justifyContent-center homepage-modal">
							<div className="col-9">
								<div
									className="col-12 flex flex-justifyContent-center flex-column flex-alignItems-center"
									style={{ textAlign: 'center' }}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: safelyDecodeURIComponent(modalProps.content),
										}}
									/>
									{modalProps.buttonEnabled && (
										<Button
											variant={'outlined'}
											label={modalProps.buttonLabel}
											size={'lg'}
											onClick={() => {
												window.open(modalProps.buttonLink, '_blank')
											}}
										/>
									)}
									<div></div>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const HomepageModal = connect(mapStateToProps)(HomepageModalPrototype)
