import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Typography } from '@components/text/text'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import Logo from './boldtrail.svg'
import BgImage from './kvcore-bg.jpg'
import { KVCoreIconGrid } from './kvcore-grid'

export function LoKationResourceKVCorePlatform() {
	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-20-sm flex flex-column flex-alignItems-start overflow-y__scroll pb-20">
				<div className="text-center col-xs-12">
					<img src={Logo} style={{ width: '200px' }} className="mb-20" />
					<Typography type="h4">Your Force Multiplier</Typography>
				</div>
				<HorizontalRule className="mt-20" />

				<KVCoreIconGrid />

				<HorizontalRule className="mb-10" />

				<p>
					Normally this program carries a $500 setup fee and a $500 monthly fee. LoKation Agents can take part
					of the BoldTrail for a $99 setup fee and $50 per month. No Contract, No obligations. First 30 days
					free.
				</p>

				<Box flex="row" gap={true} wrap={true}>
					<Button
						variant="outlined"
						size="md"
						label="Learn more"
						onClick={() => {
							window.open('https://www.insiderealestate.com/new-users', '_blank')
						}}
					/>
					<Button
						variant="outlined"
						size="md"
						label="Sample Public Facing IDX Website"
						onClick={() => {
							window.open('https://matthewsalvatoriello.lokationre.com/', '_blank')
						}}
					/>
					<Button
						variant="outlined"
						size="md"
						label="Overview Training"
						onClick={() => {
							window.open('https://lokation.thinkific.com/courses/kvcore-overview-training', '_blank')
						}}
					/>
					<Button
						variant="outlined"
						size="md"
						label="KvCore Tips and Tricks"
						onClick={() => {
							window.open('https://lokation.thinkific.com/courses/kvcore-tips-and-tricks', '_blank')
						}}
					/>
				</Box>

				<AddOnServiceList serviceIds={['KV_CORE']} />
			</div>
			<div
				className="col-xs-12 col-lg-4 bg-color__adjust-10"
				style={{
					backgroundImage: `url(${BgImage})`,
					backgroundSize: `cover`,
				}}
			></div>
		</div>
	)
}
