import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { Chip } from '@components/chip/chip'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { Paper } from '@components/paper/paper'
import { Switch } from '@components/switch/switch'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { UserSelectorModal } from 'src/modals/user-selector/user-selector'
import { AceAPI } from 'src/services/ace/ace.api'
import { BackAtYouAPI } from 'src/services/back-at-you/back-at-you.api'
import { ReferralAPI } from 'src/services/referrals/referral.api'
import { EndUserReferral } from 'src/services/referrals/referral.types'
import { ToastService } from 'src/services/toast/toast.service'

import { MLSBoardAPI } from '../../../../../services/mls-board/mls-board.api'
import { MLSBoard } from '../../../../../services/mls-board/mls-board.types'
import { UserAPI } from '../../../../../services/user/user.api'
import { UserService } from '../../../../../services/user/user.service'
import { EndUserOffboardingStatus, EndUserProps, EndUserRole } from '../../../../../services/user/user.types'
import { useUserProfile, useUserProfileDispatch } from '../../state/user-profile__state'
import { UserProfileService } from '../../user-profile.service'

function AdminTools(props: { userToEdit: EndUserProps; currentUser: EndUserProps | null }) {
	const userProfileState = useUserProfile()
	const userProfileDispatch = useUserProfileDispatch()

	const mergedUserProps = UserProfileService().applyModifiedStateToOriginalProps(
		userProfileState.modifiedProps,
		props.userToEdit,
	)
	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false
	const [switchEnabled, setSwitchEnabled] = useState(mergedUserProps.enabled)
	const [isW9Submitted, setIsW9Submitted] = useState(mergedUserProps.isW9Submitted)
	const [passwordResetButtonText, setPasswordResetButtonText] = useState('Send Reset Instructions')
	const [offboardingButtonText, setOffboardingButtonText] = useState('Send Offboarding Instructions')
	const [offboardingStatus, setOffboardingStatus] = useState<EndUserOffboardingStatus>()
	const [referrals, setReferrals] = useState<EndUserReferral[]>([])
	const [showSelectUsersModal, setShowSelectUsersModal] = useState(false)

	const userViewingThemselves = props.userToEdit.endUserId === props.currentUser?.endUserId

	const handleSwitchChange = () => {
		setSwitchEnabled((prevState) => !prevState)
		// You can also dispatch an action here if needed
		userProfileDispatch({
			type: 'update-property',
			payload: {
				key: 'enabled',
				value: !switchEnabled,
			},
		})
	}

	const handleW9SwitchChange = () => {
		setIsW9Submitted((prevState) => !prevState)
		// You can also dispatch an action here if needed
		userProfileDispatch({
			type: 'update-property',
			payload: {
				key: 'isW9Submitted',
				value: !isW9Submitted,
			},
		})
	}

	const handleRoleChange = (
		roleToAdd: EndUserRole | null,
		roleToRemove1: number,
		roleToRemove2: number,
		roleToRemove3: number,
	) => {
		let updatedRoles = roleToAdd
			? [
					...mergedUserProps.roles.filter(
						(role) =>
							role.roleId !== roleToRemove1 &&
							role.roleId !== roleToRemove2 &&
							role.roleId !== roleToRemove3,
					),
				]
			: [...mergedUserProps.roles.filter((role) => role.roleId !== roleToRemove1)]

		if (roleToAdd) {
			if (roleToAdd.roleType === 'PENDING_PAYMENT') {
				updatedRoles = [roleToAdd] // Only the PENDING_PAYMENT role
			} else if (roleToAdd.roleType === 'SUPER_ADMIN') {
				updatedRoles.push(roleToAdd)
				if (!updatedRoles.some((role) => role.roleId === 2)) {
					updatedRoles.push({ roleId: 2, roleType: 'USER' }) // Ensure the USER role for SUPER_ADMIN
				}
				if (!updatedRoles.some((role) => role.roleId === 1)) {
					updatedRoles.push({ roleId: 1, roleType: 'ADMIN' }) // Ensure the ADMIN role for SUPER_ADMIN
				}
			} else if (roleToAdd.roleType === 'ADMIN') {
				updatedRoles.push(roleToAdd)
				if (!updatedRoles.some((role) => role.roleId === 2)) {
					updatedRoles.push({ roleId: 2, roleType: 'USER' }) // Ensure the USER role for ADMIN
				}
			} else if (roleToAdd.roleType === 'TRANSACTION_MANAGEMENT_ADMIN') {
				updatedRoles.push(roleToAdd)
				if (!updatedRoles.some((role) => role.roleId === 2)) {
					updatedRoles.push({ roleId: 2, roleType: 'USER' }) // Ensure the USER role for TRANSACTION_MANAGEMENT_ADMIN
				}
			}
		} else {
			if (roleToRemove1 === 1) {
				// If removing ADMIN role, also remove SUPER_ADMIN if present
				updatedRoles = updatedRoles.filter((role) => role.roleId !== 4)
			}
			if (!updatedRoles.some((role) => role.roleId === 1) && !updatedRoles.some((role) => role.roleId === 3)) {
				updatedRoles.push({ roleId: 2, roleType: 'USER' }) // Add USER role if no other role is checked
			}
		}

		userProfileDispatch({
			type: 'update-property',
			payload: {
				key: 'roles',
				value: updatedRoles,
			},
		})
	}

	const [mlsBoardOptions, setMlsBoardOptions] = useState<MLSBoard.Complete[]>([])

	useEffect(() => {
		async function fetchOptions() {
			async function getAllMlsBoardOptions(page: number = 0, size: number = 20): Promise<MLSBoard.Complete[]> {
				const response = await MLSBoardAPI().getAllOptions({
					page,
					size,
					sort: [{ property: 'displayValue', direction: 'asc' }],
				})
				if (response.data.totalPages > page + 1) {
					return response.data.items.concat(await getAllMlsBoardOptions(page + 1, size))
				}
				return response.data.items
			}
			const mlsBoardPromise = getAllMlsBoardOptions().then((items: MLSBoard.Complete[]) => {
				setMlsBoardOptions(items)
			})
			return mlsBoardPromise
		}

		fetchOptions()
	}, [])

	function getMLSBoardOptions(): DropdownTypes.Option<MLSBoard.Complete>[] {
		const licensedStateIds = mergedUserProps.licenseInformation.map(
			(license) => license.licensedState.licensedStateId,
		)

		// Group by state
		const groupedByState = mlsBoardOptions.reduce<Record<string, DropdownTypes.ParentOption<MLSBoard.Complete>>>(
			(acc, mlsOption) => {
				if (licensedStateIds.includes(mlsOption.licensedState.licensedStateId)) {
					// Check if the state exists in the accumulator
					if (!acc[mlsOption.licensedState.abbreviation]) {
						acc[mlsOption.licensedState.abbreviation] = {
							label: mlsOption.licensedState.stateName,
							value: mlsOption.licensedState.abbreviation,
							children: [],
						} as DropdownTypes.ParentOption<MLSBoard.Complete> // Type assertion here
					}

					// Push the MLS board into the state's children
					;(
						acc[mlsOption.licensedState.abbreviation] as DropdownTypes.ParentOption<MLSBoard.Complete>
					).children.push({
						label: mlsOption.displayValue,
						value: mlsOption,
					})
				}
				return acc
			},
			{},
		)

		return Object.values(groupedByState)
	}

	function getSelectedMLSOptions(): MLSBoard.Complete[] {
		const selectedOptions: MLSBoard.Complete[] = []
		const mlsBoardIds = mergedUserProps.mlsBoards.map((board) => board.mlsBoardId)

		mlsBoardOptions.forEach((option) => {
			const isInSelectedOptions = mlsBoardIds.includes(option.mlsBoardId)
			if (isInSelectedOptions) {
				selectedOptions.push(option)
			}
		})

		return selectedOptions
	}

	const selectedMLSOption = getSelectedMLSOptions()
	const returnedMlsBoardOptions = getMLSBoardOptions()

	const isOnlyParent = returnedMlsBoardOptions.length === 1

	const email = mergedUserProps.email

	function sendResetInstructions(): void {
		UserAPI.sendResetPasswordEmail({
			email,
		}).then((res) => {
			setPasswordResetButtonText('Sent!')
			ToastService.create({
				type: 'SUCCESS',
				body: `Password reset instructions will be mailed to ${mergedUserProps.firstName} ${mergedUserProps.lastName}'s inbox`,
			})
		})
	}

	function sendOffboardingInstructions(endUserId: number): void {
		UserAPI.sendOffboardingInstructions(endUserId).then((res) => {
			setOffboardingButtonText('Sent!')
			ToastService.create({
				type: 'SUCCESS',
				body: `Offboarding instructions will be mailed to ${mergedUserProps.firstName} ${mergedUserProps.lastName}'s inbox`,
			})
		})
	}

	function createUserAce(endUserId: number): void {
		AceAPI.createUser(endUserId).then((res) => {
			ToastService.create({
				type: 'SUCCESS',
				body: `User has been created in ACE`,
			})
		})
	}

	function activateUserAce(endUserId: number): void {
		AceAPI.activateUser(endUserId).then((res) => {
			ToastService.create({
				type: 'SUCCESS',
				body: `User has been activated in ACE`,
			})
		})
	}

	function deactivateUserAce(endUserId: number): void {
		AceAPI.deactivateUser(endUserId).then((res) => {
			ToastService.create({
				type: 'SUCCESS',
				body: `User has been activated in ACE`,
			})
		})
	}

	const timestampToDateStr = (timestamp: number) => {
		const date = new Date(timestamp)
		const year = date.getUTCFullYear()
		const month = String(date.getUTCMonth() + 1).padStart(2, '0')
		const day = String(date.getUTCDate()).padStart(2, '0')
		return `${year}-${month}-${day}`
	}

	const dateStrToTimestamp = (dateStr: string) => {
		return Date.UTC(
			parseInt(dateStr.substr(0, 4), 10),
			parseInt(dateStr.substr(5, 2), 10) - 1,
			parseInt(dateStr.substr(8, 2), 10),
		)
	}

	const handleInputChange = debounce((updatedValue) => {
		userProfileDispatch({
			type: 'update-property',
			payload: {
				key: 'registrationTimestamp',
				value: dateStrToTimestamp(updatedValue),
			},
		})
	}, 1000)

	function createUserBackAtYou(endUserId: number) {
		BackAtYouAPI.createAccount(endUserId)
			.then((res) => {
				ToastService.create({
					type: 'SUCCESS',
					body: `User created successfully`,
				})
			})
			.catch((error) => {
				ToastService.create({
					type: 'ERROR',
					body: `There was an error creating this user in Back At You!`,
				})
			})
	}

	function getOffboardingStatus(endUserId: number) {
		UserAPI.getOffboardingStatus(endUserId).then((res) => {
			setOffboardingStatus(res.data)
		})
	}

	function getChipsForRecipients(): React.ReactNode {
		return (
			<>
				{referrals.map((endUser) => {
					return (
						<Chip
							label={endUser ? `${endUser.fullName}` : 'Unknown'}
							color={'var(--colorAdjust60)'}
							className="mr-10 my-5"
							onClickRemove={() => {
								if (endUser) {
									handleRemoveRecipient('USER', endUser.endUserId)
								}
							}}
						/>
					)
				})}
			</>
		)
	}
	async function handleRemoveRecipient(recipientType: 'SPECIALTY' | 'STATE' | 'USER', idToRemove: number) {
		setReferrals((prevReferrals) => {
			let updatedList = [...prevReferrals]
			switch (recipientType) {
				case 'USER':
					updatedList = updatedList.filter((user) => user.endUserId !== idToRemove)
					break
			}

			return updatedList
		})

		try {
			await ReferralAPI.removeReferralFromUser(props.userToEdit.endUserId, [idToRemove])
			ToastService.create({ type: 'SUCCESS', body: 'Referral has been removed successfully.' })
		} catch (error) {
			ToastService.create({ type: 'ERROR', body: 'THere was an error while removing this referral.' })
		}
	}

	useEffect(() => {
		getOffboardingStatus(props.userToEdit.endUserId)
	}, [])

	useEffect(() => {
		async function fetchUserReferrals() {
			try {
				const res = await ReferralAPI.getReferralsFromUser(props.userToEdit.endUserId)
				setReferrals(res.data)
			} catch (error) {
				console.error('Error fetching user referrals:', error)
			}
		}

		fetchUserReferrals()
	}, [])

	function isSuperUserAdmin(): boolean {
		const currentUserProps = props.currentUser
		if (currentUserProps) {
			return UserService.isUserSuperAdmin(currentUserProps)
		}
		return false
	}

	return (
		<>
			<Typography type="h2" margins={['bottom']}>
				User Administration
			</Typography>
			<Paper
				bgColor="primary"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				style={{ maxWidth: `800px` }}
			>
				{!canUserEdit ? null : (
					<>
						<div className="flex flex-alignItems-center mb-20">
							<strong>Account Status</strong>
							<Switch
								className="mx-5"
								checked={switchEnabled}
								onChange={handleSwitchChange}
								disabled={userViewingThemselves}
							/>
							<span>{switchEnabled ? 'Enabled' : 'Disabled'}</span>
						</div>
					</>
				)}

				<Typography type="h5">User Referrals</Typography>
				<p>If a user has any referrals, you can select multiple at a time.</p>
				<div
					className={`text-input flex flex-alignItems-center px-10 mb-40`}
					style={{ cursor: 'pointer' }}
					onClick={(event) => {
						setShowSelectUsersModal(true)
					}}
				>
					<div className="flex-fillSpace flex flex-wrap">{getChipsForRecipients()}</div>
					<FontAwesomeIcon icon={['far', 'user']} />
				</div>
				{isSuperUserAdmin() && (
					<>
						<Typography type="h5">Account Roles</Typography>
						<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
							<Checkbox
								className="mb-10"
								textAlignment="start"
								checked={mergedUserProps.roles.some((role) => role.roleId === 3)}
								disabled={userViewingThemselves}
								onChange={(state) =>
									handleRoleChange(state ? { roleId: 3, roleType: 'PENDING_PAYMENT' } : null, 3, 1, 4)
								}
							>
								<div>
									<strong>Pending Payment</strong>
									<div>User has not provided any payment and cannot access the Sphere.</div>
								</div>
							</Checkbox>
							<Checkbox
								className="mb-10"
								textAlignment="start"
								checked={mergedUserProps.roles.some((role) => role.roleId === 1)}
								disabled={userViewingThemselves}
								onChange={(state) =>
									handleRoleChange(state ? { roleId: 1, roleType: 'ADMIN' } : null, 1, 3, 4)
								}
							>
								<div>
									<strong>Administrator</strong>
									<div>
										User is an administrator and has access to edit content on the Sphere as well as
										modify the accounts of other users.
									</div>
								</div>
							</Checkbox>

							<Checkbox
								className="mb-10"
								textAlignment="start"
								checked={mergedUserProps.roles.some((role) => role.roleId === 4)}
								disabled={userViewingThemselves}
								onChange={(state) =>
									handleRoleChange(state ? { roleId: 4, roleType: 'SUPER_ADMIN' } : null, 1, 3, 4)
								}
							>
								<div>
									<strong>Super Administrator</strong>
									<div>
										User is a super administrator and has access to edit content on the Sphere as
										well as modify the accounts of other users.
									</div>
								</div>
							</Checkbox>

							<Checkbox
								className="mb-10"
								textAlignment="start"
								checked={mergedUserProps.roles.some((role) => role.roleId === 5)}
								disabled={userViewingThemselves}
								onChange={(state) =>
									handleRoleChange(
										state ? { roleId: 5, roleType: 'TRANSACTION_MANAGEMENT_ADMIN' } : null,
										1,
										3,
										4,
									)
								}
							>
								<div>
									<strong>Transaction Management Administrator</strong>
									<div>
										User is a transaction management administrator and will appear so in Transaction
										Management.
									</div>
								</div>
							</Checkbox>
						</div>
					</>
				)}

				<Typography type="h5">User Join Date</Typography>
				<p>
					Change the user's join date - if the user is a legacy subscriber, by default the join date is the
					date they were added to Sphere 3.0
				</p>
				<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
					<TextInput
						width={300}
						dataType={'text'}
						type="date"
						label="Join Date"
						// disabled={!canUserEdit}
						value={
							mergedUserProps.registrationTimestamp
								? timestampToDateStr(mergedUserProps.registrationTimestamp)
								: ''
						}
						onChange={handleInputChange}
						margins={['bottom', 'right']}
					/>
				</div>
				<Typography type="h5">User W9 Status</Typography>
				<p>
					Change this to reflect the status of the user's W9. If the toggle below is set to "Yes", then the
					user is in compliance with their W9 status. If it is set to "No", then no W9 exists for this user.
				</p>
				{!canUserEdit ? null : (
					<>
						<div className="flex flex-alignItems-center mb-20">
							<strong>Is W9 On File</strong>
							<Switch className="mx-5" checked={isW9Submitted} onChange={handleW9SwitchChange} />
							<span>{isW9Submitted ? 'Yes' : 'No'}</span>
						</div>
					</>
				)}
				{isSuperUserAdmin() && (
					<>
						<Typography type="h5">User Stripe Customer ID</Typography>
						<p>
							You are able to replace or remove this user's Stripe Customer ID. If you remove it, the next
							time this user logs in it will create a new Stripe Customer ID.
						</p>
						<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
							<TextInput
								width={300}
								dataType={'text'}
								type="text"
								label="Stripe Customer ID"
								// disabled={!canUserEdit}
								value={mergedUserProps.customerId ? mergedUserProps.customerId : ''}
								onChange={(updatedValue) => {
									userProfileDispatch({
										type: 'update-property',
										payload: {
											key: 'customerId',
											value: updatedValue,
										},
									})
								}}
								margins={['bottom', 'right']}
							/>
						</div>
					</>
				)}
				<Typography type="h5">Send Password Reset Link</Typography>
				<p>This will send a password reset to the email address {mergedUserProps.email}</p>
				<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
					<Button
						label={passwordResetButtonText}
						variant="contained"
						size={'lg'}
						onClick={sendResetInstructions}
					/>
				</div>
				<Typography type="h5">MLS Boards</Typography>
				<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
					<Dropdown<MLSBoard.Complete>
						width={`100%`}
						label="MLS Board"
						multiselect={true}
						onlyParent={isOnlyParent}
						options={returnedMlsBoardOptions}
						value={selectedMLSOption}
						onSelect={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: { key: 'mlsBoards', value: updatedValue },
							})
						}}
					/>
				</div>

				<Typography type="h5">A.C.E. Education</Typography>
				<p>Create, activate, or deactivate a user within A.C.E.</p>
				{!canUserEdit ? null : (
					<>
						<Box flex="row" margins={['bottom']} gap={true}>
							<Button
								label="Create User"
								variant="contained"
								size={'lg'}
								onClick={() => createUserAce(props.userToEdit.endUserId)}
							/>

							<Button
								label="Activate User"
								variant="contained"
								size={'lg'}
								onClick={() => activateUserAce(props.userToEdit.endUserId)}
							/>

							<Button
								label="Deactivate User"
								variant="contained"
								size={'lg'}
								onClick={() => deactivateUserAce(props.userToEdit.endUserId)}
							/>
						</Box>
					</>
				)}

				<Typography type="h5">Back At You!</Typography>
				<p>Create or deactivate a user within Back At You!</p>
				{!canUserEdit ? null : (
					<>
						<Box flex="row" margins={['bottom']} gap={true}>
							<Button
								label="Create/Activate User"
								variant="contained"
								size={'lg'}
								onClick={() => createUserBackAtYou(props.userToEdit.endUserId)}
							/>

							<Button
								label="Activate User"
								variant="contained"
								size={'lg'}
								onClick={() => BackAtYouAPI.activateUser(props.userToEdit.email)}
							/>

							<Button
								label="Deactivate User"
								variant="contained"
								size={'lg'}
								onClick={() => BackAtYouAPI.deactivateUser(props.userToEdit.email)}
							/>
						</Box>
					</>
				)}

				<Typography type="h5">Send Offboarding Email</Typography>
				{offboardingStatus && offboardingStatus.instructionsSent ? (
					<div className="mt-10">
						<em>
							Offboarding instructions have been sent to this user on{' '}
							{new Date(offboardingStatus.instructionsSentTimestamp).toLocaleDateString('en-US', {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							})}
							{' at '}
							{new Date(offboardingStatus.instructionsSentTimestamp).toLocaleTimeString('en-US', {
								hour: '2-digit',
								minute: '2-digit',
								hour12: true,
							})}
							. If you would like to send them again, you can click the button below.
						</em>
					</div>
				) : (
					<p>This will send an offboarding email to {mergedUserProps.email}</p>
				)}

				<Button
					label={offboardingButtonText}
					margins={['top', 'bottom']}
					variant="contained"
					size={'lg'}
					onClick={() => sendOffboardingInstructions(props.userToEdit.endUserId)}
				/>
			</Paper>
			{showSelectUsersModal && (
				<UserSelectorModal
					selectedRecipients={{
						endUsers: referrals.map((user) => user.endUserId),
						licensedStates: [],
						specialties: [],
						licensedRegions: [],
						endUsersByPaymentMethod: [],
					}}
					zIndex={10000}
					onHide={() => {
						setShowSelectUsersModal(false)
					}}
					onSelectUsers={async (updatedRecipients) => {
						try {
							const res = await ReferralAPI.updateReferralsForUser(
								props.userToEdit.endUserId,
								updatedRecipients.endUsers,
							)
							setReferrals(res.data)
							ToastService.create({
								type: 'SUCCESS',
								body: 'Referrals have been updated successfully.',
							})
						} catch (error) {
							ToastService.create({
								type: 'ERROR',
								body: 'There was an error while updating referrals.',
							})
						} finally {
							setShowSelectUsersModal(false)
						}
					}}
					displayOptions={['user']}
				/>
			)}
		</>
	)
}

export default AdminTools
