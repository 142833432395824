import { Button } from '@components/button/button'
import { EmailNotificationComponent } from '@components/email-notification/email-notification'
import { Typography } from '@components/text/text'

import BrokerImg from './broker.jpg'
import CeImg from './ce-shop.jpg'

export function LoKationResourceBrokerXProgram() {
	return (
		<div className="overflow-y__scroll py-20">
			<div className="col-xs-12 pr-40-md-down pr-20-lg flex flex-column flex-alignItems-center overflow-y__scroll">
				<div className="col-12 flex flex-justifyContent-center mb-30">
					<Typography type="normal" margins={['bottom']} variant={['center']}>
						Becoming a <strong>Mortgage Loan Originator (MLO)</strong> is a <strong>game-changer</strong>{' '}
						for LoKation agents. By getting licensed, you <strong>own the full transaction</strong>,
						increase your earning potential, and provide a seamless experience for your clients—all while
						keeping more money in your pocket.
					</Typography>
				</div>

				<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
					<div
						className="col-md-12 col-8 flex flex-column flex-justifyContent-center mb-20"
						style={{ maxWidth: '400px' }}
					>
						<Typography type="h4" margins={['bottom']} variant={['center']}>
							Why Get Your MLO License?
						</Typography>
						<ul>
							<li>
								<strong>Earn More</strong> – Make money on both the sale & the loan
							</li>
							<li>
								<strong>Control the Process</strong> – Be the go-to expert for your clients from start
								to finish
							</li>
							<li>
								<strong>Differentiate Yourself</strong> – Stand out in a competitive market with added
								value
							</li>
						</ul>
					</div>

					<div className="col-12 flex flex-column flex-justifyContent-center flex-alignItems-center mb-20">
						<strong>
							More Deals. More Money. More Control. Now’s the time to invest in yourself and take your
							business to the next level!
						</strong>
					</div>

					<div className="col-12 flex flex-column-xs-down flex-columnReverse-xs-down">
						<div className="col-12 col-md-6 pr-10-md">
							<div className="col-12">
								<img src={BrokerImg} className="mb-20 col-xs-12" />
							</div>
							<Typography type="h6" margins={['bottom']} variant={['center']}>
								Recommended: The Broker X Program (UWM) – $300
							</Typography>
							<Typography type="strong" margins={['bottom']} variant={['center']}>
								The best way to get licensed with expert support and immersive, hands-on training!
							</Typography>
							<ul className="mb-20" style={{ minHeight: '160px' }}>
								<li>
									<strong>5-Week Comprehensive Training</strong> – NMLS-approved virtual coursework +
									test prep
								</li>
								<li>
									<strong>Exclusive In-Person Experience at UWM HQ</strong> – 3-day hands-on training,
									role-playing, and networking (included in the $300 fee!)
								</li>
								<li>
									<strong>Guided Learning with Live Support</strong> – Get expert coaching from top
									industry leaders
								</li>
								<li>
									<strong>Best for Agents Who Want to Learn from the Best & Maximize Success</strong>
								</li>
							</ul>
							<Typography type="strong" margins={['bottom']} variant={['center']}>
								This is the ultimate path for serious agents who want to master mortgage lending and
								take their business to the next level.
							</Typography>
							<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
								<div className="mx-10">
									<EmailNotificationComponent
										serviceToNotify={'brokerXProgram'}
										title="Enroll Now"
										noStyles={true}
										buttonLabel="Enroll Now"
									/>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 pl-10-md">
							<div className="col-12">
								<img src={CeImg} className="mb-20 col-xs-12" />
							</div>
							<Typography type="h6" margins={['bottom']} variant={['center']}>
								Pre-Licensing Alternative: The CE Shop (Self-Paced) – $304.50
							</Typography>
							<Typography type="strong" margins={['bottom']} variant={['center']}>
								A flexible, independent learning option for those who prefer to go at their own speed.
							</Typography>
							<ul className="mb-20" style={{ minHeight: '160px' }}>
								<li>
									<strong>20-Hour NMLS-Approved Online Course</strong> – Covers federal & state
									requirements
								</li>
								<li>
									<strong>Learn at Your Own Pace</strong> – Must be completed within 7 calendar days
								</li>
								<li>
									<strong>Final Exam with 3 Attempts</strong> – Pass with a 70% or higher
								</li>
								<li>
									<strong>Discounted Price: $304.50</strong> (Normally $435 – Use{' '}
									<strong>Promo Code: LOKATION30</strong>)
								</li>
								<li>
									<strong>Best for Self-Motivated Agents Who Want a Quick & Flexible Option</strong>
								</li>
							</ul>
							<Typography type="strong" margins={['bottom']} variant={['center']}>
								A pre-licensing alternative for those who prefer solo learning, but it lacks the
								hands-on training and in-person benefits of Broker X.
							</Typography>
							<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
								<div className="mx-10">
									<Button
										variant="contained"
										size="md"
										margins={['bottom']}
										label={'Enroll Now'}
										onClick={() => {
											window.open(`https://lokation.theceshop.com/mortgage`, `_blank`)
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
