import { Typography } from '@components/text/text'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import Logo from './lofty-logo.webp'

export function LoKationResourceLofty() {
	return (
		<div className="overflow-y__scroll py-20">
			<div className="col-xs-12 pr-40-md-down pr-20-lg flex flex-column flex-alignItems-start overflow-y__scroll">
				<div className="col-12 flex flex-justifyContent-center">
					<img src={Logo} style={{ width: '400px' }} />
				</div>

				<p>
					Lofty is your all-in-one, AI-powered platform designed to help real estate agents and teams{' '}
					<strong>increase productivity, capture more leads, and close more deals—faster</strong>. With
					award-winning technology, Lofty streamlines every step of your real estate journey, from search to
					settlement.
				</p>

				<p></p>

				<div className="flex flex-wrap">
					<div className="col-xs-12 col-md-6 pr-20-lg">
						<Typography type="h5" margins={['bottom']}>
							With Lofty, you’ll be able to:
						</Typography>
						<ul>
							<li>
								<strong>Capture and Convert More Leads</strong>
								<br />
								<p>Get more qualified leads with AI-powered lead generation tools</p>
							</li>
							<li>
								<strong>Save Time on Marketing</strong>
								<br />
								<p>
									Automated social media ads and drip campaigns help you stay top of mind with minimal
									effort.
								</p>
							</li>
							<li>
								<strong>Streamline the Sales Process</strong>
								<br />
								<p>
									Effortlessly manage leads, listings, and client communications with our intuitive
									CRM.
								</p>
							</li>

							<li>
								<strong>Boost Productivity</strong>
								<br />
								<p>
									Work smarter, not harder, by automating everyday tasks and focusing on what matters
									most.
								</p>
							</li>
							<li>
								<strong>Maximize Team Collaboration</strong>
								<br />
								<p>Built-in collaboration tools to keep your team connected and on track.</p>
							</li>
							<li>
								<strong>Save $$$ on Tech Tools</strong>
								<br />
								<p>
									With Lofty’s all-in-one platform, you’ll cut costs on multiple subscriptions and
									tools.
								</p>
							</li>
						</ul>
					</div>
					<div className="col-xs-12 col-md-6 pr-20-lg">
						<Typography type="h5" margins={['bottom']}>
							Lofty Features Include:
						</Typography>
						<ul>
							<li>
								<strong>AI-Powered CRM</strong>
								<br />
								<p>Automatically manage leads, contacts, and follow-ups.</p>
							</li>
							<li>
								<strong>Automated Social Media Marketing</strong>
								<br />
								<p>Run ad campaigns on social media without lifting a finger.</p>
							</li>
							<li>
								<strong>360° Lead Generation</strong>
								<br />
								<p>Capture leads from all sources in one place, effortlessly.</p>
							</li>

							<li>
								<strong>AI Assistant</strong>
								<br />
								<p>Get proactive support for your business with AI-powered tools.</p>
							</li>
							<li>
								<strong>Dynamic CMA</strong>
								<br />
								<p> Automatically generate competitive market analysis reports that impress sellers.</p>
							</li>
							<li>
								<strong>Gain Actionable Insights</strong>
								<br />
								<p>
									Track performance, analyze trends, and make data-driven decisions to grow your
									business faster.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
				<Typography type="h4" margins={['bottom']} variant={['center']}>
					<strong>Normally priced at $399/month</strong>, with Lofty you pay only <strong>$50/month</strong>{' '}
					as a LoKation agent!
				</Typography>
				<div className="mx-10">
					<AddOnServiceList serviceIds={['LOFTY']} />
				</div>
			</div>
		</div>
	)
}
